import React from "react";
import Slick from "../components/Slick";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const Hero = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
					}
				}
			}
		`
	);
	return (
		<section
			className="mb-20 relative"
			// style={{ minHeight: `calc(100vh - 100px)` }}
		>
			<div className="flex flex-col">
				<div className="mb-6 flex items-center gap-6">
					<h1>Welcome to {data.site.siteMetadata.title}</h1>
					<span className="inline-block w-8 h-px bg-gray-300"></span>
					<figure className="flex items-center gap-2">
						<StaticImage
							src="../images/facebook.png"
							alt=""
							width={30}
							height={30}
							layout="fixed"
							quality={95}
							formats={["AUTO", "WEBP", "AVIF"]}
						/>
						<StaticImage
							src="../images/instagram.png"
							alt=""
							className="inline-block"
							width={30}
							height={30}
							layout="fixed"
							quality={95}
							formats={["AUTO", "WEBP", "AVIF"]}
						/>
					</figure>
				</div>
				<div className="-ml-10">
					<Slick />
				</div>
			</div>
			<span
				className="absolute bottom-0 right-0 text-sm tracking-widest"
				style={{ writingMode: `vertical-rl` }}
			>
				scroll <span className="inline-block w-px h-16 bg-gray-300"></span>
			</span>
		</section>
	);
};

export default Hero;
