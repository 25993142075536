import React from "react";
import { Helmet } from "react-helmet";
// Custom Components
import Layout from "../components/Layout";
import NewArticles from "../components/News";
import Hero from "../components/Hero";
import Section from "../components/Section";
import Contact from "../components/Contact";
import Sample from "../components/Sample";
import Price from "../components/Price";
import Faq from "../components/Faq";
import Flow from "../components/Flow";
import About from "../components/About";

const IndexPage = () => {
	return (
		<>
			<Helmet>
				<title>レンタルスペース E&amp;Be</title>
			</Helmet>
			<Layout>
				<Hero />
				<Section id="about" title="E&Beについて" en="About">
					<About />
				</Section>
				<Section id="news" title="お知らせ" en="News">
					<NewArticles />
				</Section>
				<Section id="useSample" title="ご利用例" en="UseSample">
					<Sample />
				</Section>
				<Section id="price" title="ご利用料金" en="Price">
					<Price />
				</Section>
				<div className="lg:grid lg:grid-cols-2">
					<Section id="flow" title="ご利用時の流れ" en="Flow">
						<Flow />
					</Section>
					<Section id="faq" title="よくある質問" en="Faq">
						<Faq />
					</Section>
				</div>
				<Section id="contact" title="お問い合わせ" en="Contact">
					<Contact />
				</Section>
			</Layout>
		</>
	);
};

export default IndexPage;
