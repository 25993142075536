import React from "react";

const About = () => {
	return (
		<div>
			<p>AboutUs</p>
		</div>
	);
};

export default About;
