import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

const NewArticles = () => {
	// 記事の並び順は投稿日を基準
	const data = useStaticQuery(
		graphql`
			{
				allMarkdownRemark(
					sort: { order: DESC, fields: [frontmatter___createdAt] }
					limit: 3
				) {
					nodes {
						html
						fields {
							slug
						}
						frontmatter {
							createdAt(formatString: "YYYY.MM.DD")
							title
						}
					}
				}
			}
		`
	);

	return (
		<ul className="">
			{data.allMarkdownRemark.nodes.map((node) => {
				return (
					<li className="pb-4 lg:text-lg" key={node.frontmatter.title}>
						<Link
							className="hover:opacity-80 lg:flex lg:items-center lg:justify-start"
							to={`/news${node.fields.slug}`}
						>
							<time
								className="inline-block text-sm lg:text-base py-1 text-accent lg:pr-4"
								dateTime={node.frontmatter.createdAt}
							>
								{node.frontmatter.createdAt}
							</time>
							<h3>{node.frontmatter.title}</h3>
						</Link>
					</li>
				);
			})}
		</ul>
	);
};

export default NewArticles;
