import React from "react";

const Price = () => {
	return (
		<>
			<div className="overflow-x-scroll pt-8">
				<table
					className="border-collapse table-fixed w-[490px]
				md:w-full
				"
				>
					<tbody className="text-center">
						<tr className="text-white">
							<th className="py-4 bg-gray-200 text-theme">●●●●プラン</th>
							<th
								className="relative w-48 bg-accent -top-4
									md:w-80 lg:w-[500px]
							"
							>
								<span className="absolute -top-4 bg-theme text-xs inline-block p-1 px-2 rounded-full">
									人気No.1
								</span>
								●●●●プラン
							</th>
							<th className="bg-theme">●●●●プラン</th>
						</tr>
						<tr className="border-b border-gray-200 text-xl">
							<td className="border-l border-gray-200">¥0</td>
							<td className="py-2 text-2xl shadow-lg">¥5,000</td>
							<td className="border-r border-gray-200">¥8,000</td>
						</tr>
					</tbody>
				</table>
			</div>
			<p className="mt-2 text-sm text-gray-500 tracking-wide md:hidden">
				※横にスライドできます
			</p>
		</>
	);
};

export default Price;
