import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const Sample = () => {
	return (
		<>
			<div className="relative mb-12">
				<div
					className="
					backdrop-filter backdrop-blur bg-white bg-opacity-70 p-4 absolute -left-4 mr-8 z-10
					top-1/2 tansform -translate-y-1/2 md:w-[500px] md:bg-opacity-40
					lg:h-[350px] lg:p-8 xl:left-0
				"
				>
					<h3 className="mb-8 lg:text-xl text-right">〇〇〇〇〇〇〇〇〇〇</h3>
					<p className="text-right lg:text-lg">
						ご利用例の詳細ご利用例の詳細ご利用例の詳細ご利用例の詳細ご利用例の詳細ご利用例の詳細
					</p>
				</div>
				<figure className="pl-8">
					<StaticImage
						className="-mr-4 lg:w-[1000px] lg:h-[618px] xl:mr-0"
						src="../images/04.jpg"
						placeholder="blurred"
						alt=""
						width={500}
						height={500}
						layout="constrained"
					/>
				</figure>
			</div>
			<div className="relative mb-32">
				<div
					className="
					backdrop-filter backdrop-blur bg-white bg-opacity-70 p-4 absolute -right-4 ml-8 z-10
					top-1/2 transform -translate-y-1/2 md:w-[500px] md:bg-opacity-40
					lg:h-[350px] lg:p-8 xl:right-0
					"
				>
					<h3 className="mb-8 lg:text-xl">〇〇〇〇〇〇〇〇〇〇</h3>
					<p className="text-left lg:text-lg">
						ご利用例の詳細ご利用例の詳細ご利用例の詳細ご利用例の詳細ご利用例の詳細ご利用例の詳細
					</p>
				</div>
				<figure className="pr-8">
					<StaticImage
						className="-ml-4 lg:w-[1000px] lg:h-[618px] xl:ml-0"
						src="../images/02.jpg"
						placeholder="blurred"
						alt=""
						width={500}
						height={500}
						layout="constrained"
					/>
				</figure>
			</div>
		</>
	);
};

export default Sample;
