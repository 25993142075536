import React, { Component } from "react";
import Slider from "react-slick";
import { StaticImage } from "gatsby-plugin-image";

export default class Slick extends Component {
	render() {
		const settings = {
			autoplay: true,
			infinite: true,
			fade: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
		};
		return (
			<>
				<Slider {...settings}>
					<figure className="text-center">
						<StaticImage
							className=""
							src="../images/01.jpg"
							layout="constrained"
							width={300}
							height={580}
							alt=""
							placeholder="blurred"
						/>
					</figure>
					<figure className="text-center">
						<StaticImage
							imgClassName=""
							src="../images/02.jpg"
							layout="constrained"
							width={300}
							height={580}
							alt=""
							placeholder="blurred"
						/>
					</figure>
					<figure className="text-center">
						<StaticImage
							imgClassName=""
							src="../images/03.jpg"
							layout="constrained"
							width={300}
							height={580}
							alt=""
							placeholder="blurred"
						/>
					</figure>
				</Slider>
			</>
		);
	}
}
